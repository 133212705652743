:root {
  --swiper-pagination-color: #ffffff !important;
  --swiper-pagination-bullet-size: 11px !important;
  --swiper-pagination-bullet-inactive-color: #c4c4c4 !important;
  --swiper-pagination-bullet-inactive-opacity: 1 !important;
}

.pastCompetitionsHero {
  height: 100%;
  cursor: pointer;

  &Slide {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    &Img {
      width: 100%;
      height: 100%;
    }
  }
}

.hiddenNavigation {
  display: none;
}
