@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100;300;400;700&family=Roboto:wght@100;300;400;700&display=swap');

// Colors
/* ----------- Primary ------------ */
$color-primary: #fad778;
$color-secondary: #9c7425;
$blue: #273f65;

/* ----------- Semantic ------------ */
$error-color: #d87a7a;
$success-color: #7cd87a;
$warning-color: #d8d77a;

/* ----------- Nuetrals ------------ */
$black: #000;
$white: #fff;
$gray-100: #121212;
$gray-200: #181818;
$gray-300: #212121;
$gray-350: #2d2d2d;
$gray-400: #333333;
$gray-450: #393937;
$gray-475: #6a6a6a;
$gray-500: #b5b5b5;

// Spacing
$spacer: 1rem;

// Typography

$font-family-base: 'Roboto', sans-serif;
$font-family-condensed: 'Roboto Condensed', sans-serif;
$font-size-base: 1rem;

$h1-font-size: 30px;
$h2-font-size: 26px;
$h3-font-size: 21px;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

$h1-font-weight: 700;
