@import '../../styles/variables';

.mobileSearch {
  position: relative;
  z-index: 2;

  &Button {
    background: transparent;
    border: none;
    height: 42px;
    position: absolute;
    top: -24px;
    right: 0;
    z-index: 10;
    cursor: pointer;

    img {
      height: 100%;
    }
  }

  &Content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $gray-100;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;

    &List {
      padding: 0;
      margin: 0;
    }
  }

  &Header {
    display: flex;
    align-items: center;
    padding: 18px;
  }

  &BackBtn {
    background: transparent;
    border: none;
    width: 60px;
  }

  &Field {
    background: $gray-350;
    position: relative;
    width: calc(100% - 130px);
  }

  &Input {
    color: $white;
    background: transparent;
    border: none;
    border-radius: 0;
    font-size: 28px;
    font-weight: 100;
    padding: 12.5px;
    width: 100%;
    &::placeholder {
      position: absolute;
      top: 18px;
      font-size: 20px;
      word-spacing: -1.5px;
    }
  }

  &__active {
    .mobileSearchButton {
      position: fixed;
      top: 26px;
      right: 24px;
      z-index: 10;
    }

    .mobileSearchContent {
      position: fixed;
      transform: translateX(0);
      opacity: 1;
    }
  }
}
