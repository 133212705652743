:root {
  --swiper-pagination-color: #ffffff !important;
  --swiper-pagination-bullet-size: 11px !important;
  --swiper-pagination-bullet-inactive-color: #c4c4c4 !important;
  --swiper-pagination-bullet-inactive-opacity: 1 !important;
}

.mainHeroContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  &Live {
    padding: 20px;
    justify-content: space-around;
    font-weight: 700;
    margin: 0;
    font-size: 74px;
    background: linear-gradient(90deg,
        #273f65 0.87%,
        rgba(39, 63, 101, 0.41) 98.11%);
    color: #d3ccb5;
  }

}

.mainHero {
  height: 100%;
  cursor: pointer;
  display: flex;
  color: #d3ccb5;

  &Swiper {
    background: linear-gradient(90deg,
        #273f65 0.87%,
        rgba(39, 63, 101, 0.41) 98.11%);
  }

  &Link {
    width: 100%;
    color: #d3ccb5;
    text-decoration: none;
    display: flex;
  }

  &Slide {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    background: transparent;

    &Info {
      width: 65%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-around;


      &Title {
        font-weight: 700;
        margin: 0;
        font-size: 39px;

        &Channel {
          font-weight: 300;
          font-style: italic;
        }
      }

      &Description {
        width: 100%;
        font-weight: 400;
        margin: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 21px;

        &Medal {
          display: flex;
          flex-wrap: nowrap;

          &Text {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        img {
          margin-right: 12px;
        }
      }
    }

    &Img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &Container {
        width: 35%;
        height: 60%;
        padding-right: 20px;
        align-self: flex-end;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 35%;
          height: 100%;
          display: inline-block;
          background: linear-gradient(180deg,
              rgba(39, 63, 101, 0) 44.8%,
              #273f65 100%);
        }
      }
    }
  }
}

.hiddenNavigation {
  display: none;
}
