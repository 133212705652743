.countdown {
  display: flex;
  column-gap: 12px;
  align-items: center;
  &Text {
    min-width: fit-content;
  }
  &Node {
    font-weight: bold;
    min-width: fit-content;
  }
}
