@import '../../styles/variables';

.mainHeader {
  height: 104px;
  background: $gray-300;

  &Content {
    padding: 24px;
    display: grid;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr;

    &Business {
      text-decoration: none;
      color: $color-primary;
      display: flex;
      cursor: pointer;
      margin-left: auto;

      &Container {
        text-decoration: none;
        color: $color-primary;
        display: flex;
        white-space: nowrap;

        &Logout {
          display: flex;
          flex-grow: 1;
          order: 3;
          color: $color-primary;
          justify-content: flex-end;
          padding-right: 16px;

          > * {
            cursor: pointer;
          }
        }
      }

      &Image {
        margin: 0 12px;
      }

      &Text {
        font-size: 20px;
        margin: 0;
      }
    }
  }
}

.logo {
  flex-grow: 1;
  max-width: 35%;

  img {
    cursor: pointer;
  }
}

.searchField {
  flex-grow: 2;
  max-width: 430px;
}

.mobileNav {
  flex-grow: 1;
  flex-basis: 1px;
}

.mobileNavButton {
  background: transparent;
  border: none;
}

.mobileSearch {
  flex-grow: 1;
  text-align: right;
  flex-basis: 1px;
}

@media (max-width: 768px) {
  .logo {
    max-width: 100%;
  }

  .mainHeader {
    height: 100px;
    justify-content: space-between;
  }

  .logo {
    flex-grow: 1;
    text-align: center;
    flex-basis: 1px;
  }

  .searchField {
    display: none;
  }
}

@media (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .logo {
    max-width: 100%;
  }

  .mainHeader {
    height: 100px;
    justify-content: space-between;
  }

  .logo {
    flex-grow: 1;
    text-align: center;
    flex-basis: 1px;
  }

  .searchField {
    display: none;
  }
}

@media (min-width: 1024px) {
  .mainHeaderContent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .mainHeaderContent > .mobileNav {
    order: 3;
    margin-left: auto;
    max-width: 60px;
  }

  .mainHeaderContent > .logo {
    order: 1;
  }

  .mainHeaderContent > .searchField {
    order: 2;
  }
}
