.gigg-watermark {
  width: 5rem;
  font-size: 0.7rem;
  z-index: 2;
  text-align: center;

  &.animate {
    animation: grow 10s forwards;
  }

  svg {
    margin-top: 2px;
  }

  div {
    color: #aaa;
  }
}

@media (max-width: 900px) {
  .gigg-watermark {
    width: 3rem;
    font-size: 0.5rem;
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}
