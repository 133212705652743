@import '../../styles/variables';
@import '../../styles/utilities';

.searchField {
  background: $gray-100;
  position: relative;

  &Inner {
    display: flex;
  }

  &Icon {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 18px;
      cursor: pointer;
    }
  }

  &Input {
    color: $white;
    background: transparent;
    border: none;
    border-radius: 0;
    font-size: 18px;
    font-weight: 300;
    padding: 17.5px;
    width: calc(100% - 72px);

    &:focus {
      outline: none;
    }
  }

  &Menu {
    position: absolute;
    padding: 8px 0;
    margin: 0;
    list-style-type: none;
    background: $gray-400;
    width: 100%;
    max-width: 554px;
    font-size: 24px;
    font-weight: 500;
    z-index: 10;
  }
}

.item {
  padding: 9px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s;
  cursor: pointer;

  &__selected {
    background: $gray-300;
  }

  &Label {
    @extend %truncate;
    padding-right: 20px;
  }

  &Type {
    display: inline-flex;

    &Label {
      font-family: $font-family-condensed;
      background: $gray-475;
      padding: 3px 8px;
      border-radius: 20px;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 700;

      &__active {
        background: $color-primary;
        color: $gray-350;
      }

      &__channel {
        background: $blue;
      }
    }
  }
}
