@import '../../styles/variables';

.mainFooter {
  background: $blue;
  padding: 40px 24px;

  &Content {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    column-gap: 24px;

    &Left {
      flex-grow: 1;
    }

    &Right {
      border-left: 1px solid $color-primary;
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: center;

      img {
        width: 100px;
        height: auto;
        cursor: pointer;
      }
    }
  }

  &ContactInfo {
    flex-grow: 1;
    a {
      font-family: $font-family-condensed;
      color: $white;
      font-style: normal;
      font-weight: 700;
      text-decoration: none;
      font-size: 22px;
      line-height: 25.78px;
    }
  }

  &NavContainer {
    min-width: 296px;
  }

  &Nav {
    list-style-type: none;
    margin: 33px 0 0;
    padding: 0;
    display: flex;
    align-items: center;

    &Item {
      margin-right: $spacer;
      padding-right: $spacer;
      mix-blend-mode: pass-through;

      a {
        font-family: $font-family-condensed;
        color: $white;
        font-style: normal;
        font-weight: 300;
        text-decoration: none;
        font-size: 22px;
        text-transform: uppercase;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    a {
      color: $white;
      text-decoration: none;
    }
  }
}

.hidden {
  display: none;
}

@media (max-width: 660px) {
  .mainFooter {
    padding: 24px 12px;
    &Content {
      &Right {
        padding: 0 24px;
      }
    }
  }
}

@media (max-width: 440px) {
  .mainFooter {
    &Content {
      padding: 0;

      &Right {
        padding: 0 14px;

        img {
          width: 100px;
        }
      }
    }
  }
}
