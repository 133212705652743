.competition {
  height: 100%;
  width: 100%;
  bottom: 0px;
  width: 100%;

  &Loading {
    min-height: calc(100vh - 104px - 153px);
  }
}

.iframe {
  display: none;
  padding-top: 100px;
  height: 100%;
  width : 100%;

  &__loaded {
    display: block;
  }

  &__fullscreen {
    position: fixed;
    top: 0;
    padding-top: 0;
    z-index: 2;
  }
}
