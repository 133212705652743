@import '../../styles/variables';

.channel {
  &Content {
    padding: 0 24px 0 24px;
    max-width: 1440px;
    margin: 0 auto;
    // 100vh - header - footer
    min-height: calc(100vh - 104px - 153px);
  }

  &Header {
    padding: 48px 0;
    &Back {
      display: none;
    }

    &Name {
      font-size: 30px;
      font-weight: 700;
    }
  }

  &Competitions {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 24px;
    margin-bottom: 100px;
  }

  &Competition {
    background: $black;
    height: 181px;
  }
}

@media (max-width: 768px) {
  .channel {
    &Content {
      padding: 0;
    }

    &Header {
      background-color: #2d2d2d;
      padding: 24px;
      position: relative;
      display: flex;
      text-align: center;

      &Back {
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 36px;
        transform: translate(-50%, -50%);
      }
      &Name {
        font-family: $font-family-condensed;
        padding: 0 24px;
        width: 100%;
      }
    }

    &Competitions {
      grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    }

    &Competition {
      height: 232px;
    }
  }
}
