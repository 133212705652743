.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 2;
}

.mobileNavigation {
  color: #ffffff;
  padding: 1rem;

  &Menu {
    background-color: #212121;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-size: 32px;
    font-weight: 500;
    width: 377px;
    max-width: -moz-fit-content;
    max-width: fit-content;
    line-height: 37px;
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 3;
    ul {
      list-style: none;
      padding: 22px;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      row-gap: 24px;
      a {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 37px;
        text-decoration: none;
        color: #ffffff;
      }
    }
  }
  &__Hidden {
    opacity: 0;
  }
}

@media (max-width: 470px) {
  .mobileNavigation {
    &Menu {
      font-size: 24px;
    }
  }
}

@media (min-width: 1024px) {
  .mobileNavigationMenu {
    top: 2rem;
    left: auto;
    right: 2rem;
    font-size: 28px;
  }
}
