@import '../../styles/variables.scss';

.competitionTile {
  display: flex;
  flex-wrap: wrap;
  min-width: 280px;
  height: 181px;
  position: relative;
  cursor: pointer;
  font-family: 'Roboto Condensed';
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(0, 0, 0, 0.65)),
      color-stop(100%, rgba(0, 0, 0, 0))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    ); /* IE10+ */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    ); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  }

  &Thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &Title {
    z-index: 1;
    position: absolute;
    bottom: 5px;
    left: 5px;

    &__Name {
      font-size: 1.4rem;
      font-weight: bold;
      margin: 0;
      color: white;
    }

    &__Org {
      font-size: 1.4rem;
      margin: 0;
      color: #ffffff;
    }
  }

  &Status {
    background-color: $color-primary;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    padding: 5px 10px;
    text-transform: uppercase;

    &__Paused {
      background-color: $gray-500;
    }

    &__Cta {
      font-size: 1rem;
      font-weight: bold;
      color: #000000;
      margin: 0;
    }

    &__Date {
      font-size: 1rem;
      font-weight: lighter;
      color: #000000;
      margin: 0;
    }
  }
}
