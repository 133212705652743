@import '../../styles/variables.scss';

.pageSkeleton {
  opacity: 1;

  &Body {
    padding: 48px;
    max-width: 1440px;
    margin: 0 auto;
  }

  &Header {
    height: 64px;
    width: 50%;
    background: $gray-350;
    margin-bottom: 48px;
    animation: skeletonLoading 1.5s infinite;
  }

  &Video {
    aspect-ratio: 16/9;
    width: 100%;
    background: $gray-350;
    margin-bottom: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: skeletonLoading 1.5s infinite;
  }

  &Row {
    height: 34px;
    width: 90%;
    margin-bottom: 24px;
    background: $gray-350;
    animation: skeletonLoading 1.5s infinite;

    &:nth-child(2) {
      width: 80%;
    }

    &:nth-child(3) {
      width: 60%;
    }
  }

  &Bracket {
    padding: 24px;
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Watermark {
    width: 5rem;
  }

  &BracketHeader {
    height: 115px;
    width: 100%;
    background: $gray-350;
    animation: skeletonLoading 1.5s infinite;
  }

  &Battle {
    height: 100px;
    width: 100%;
    border-radius: 4px;
    background: $gray-350;
    margin-bottom: 28px;
    animation: skeletonLoading 1.5s infinite;

    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
    &:nth-child(4) {
      animation-delay: 0.3s;
    }
    &:nth-child(5) {
      animation-delay: 0.4s;
    }
    &:nth-child(6) {
      animation-delay: 0.5s;
    }
    &:nth-child(7) {
      animation-delay: 0.6s;
    }
    &:nth-child(8) {
      animation-delay: 0.7s;
    }
  }

  &__SUBMISSIONS {
    opacity: 0.5;
  }

  &Qualifying {
    padding: 48px;
    max-width: 1190px;
    margin: 0 auto;
  }

  &Actions {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &Search {
    width: 350px;
    height: 50px;
    border-radius: 4px;
    background: $gray-350;
    animation: skeletonLoading 1.5s infinite;
  }

  &Sort {
    width: 170px;
    height: 50px;
    border-radius: 4px;
    background: $gray-350;
    animation: skeletonLoading 1.4s infinite;
  }

  &Cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    padding-bottom: 80px;
  }

  &Card {
    height: 300px;
    background: $gray-350;
    border-radius: 5px;
    animation: skeletonLoading 1.6s infinite;
  }
}

@keyframes skeletonLoading {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}
