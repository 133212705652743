@import '../../styles/variables';

.pastCompetitions {
  &Content {
    min-height: calc(100vh - 256px);
  }

  &Heading {
    max-width: 1440px;
    margin: 0 auto;
    margin-bottom: 38px;
  }

  &Hero {
    max-width: 1440px;
    margin: 0 auto;
  }

  &Competitions {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 24px;
    margin-bottom: 100px;
    max-width: 1440px;
    margin: 0 auto;

    &Container {
      background: linear-gradient(#A83D66, #a83d6600);
      width: 100%;
      padding: 48px 24px;
    }
  }

  &Competition {
    background: $black;
    height: 181px;
  }
}

@media (max-width: 768px) {
  .home {
    &Content {
      padding: 0;
    }
  }
}
