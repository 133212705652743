@import '../../styles/variables';

.home {
  &Content {
    min-height: calc(100vh - 256px);
  }

  &Hero {
    height: 620px;
    max-width: 1440px;
    margin: 0 auto;
  }

  &Competitions {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 24px;
    max-width: 1440px;
    margin: 0 auto;
    margin-bottom: 50px;

    &Container {
      background: linear-gradient(180deg, #273F65 0%, rgba(39, 63, 101, 0) 100%);
      width: 100%;
      padding: 48px 24px;
    }
  }

  &Competition {
    background: $black;
    height: 181px;
  }

  &PastCompetitions {
    padding: 0 24px;
    
    &Hero {
      max-width: 1440px;
      margin: 0 auto;
    }

    &Heading {
      font-size: 30px;
      font-weight: 700;
      text-decoration: none;
      color: white;
      max-width: 1440px;
      margin: 0 auto;
      margin-bottom: 38px;
      padding-top: 16px;
      border-top: 4px solid #9C7425;
    }

    &SeeMore {
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
      color: white;
      max-width: 1440px;
      margin: 0 auto;
      margin-bottom: 38px;
      cursor: pointer;
    }

    &Container {
      background: linear-gradient(#a83d66, #a83d6600);
      width: 100%;
      padding: 48px 24px;
    }
  }
}

@media (max-width: 768px) {
  .home {
    &Content {
      padding: 0;
    }

    &PastCompetitions {
      &Heading {
        padding-top: 0;
        border-top: none;
      }
    }
  }
}
